

































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue } from "vue-property-decorator";
import { State, Action } from "vuex-class";
type Headers = {
  text: string;
  align?: string;
  value: string;
  sortable?: boolean;
};
type Desserts = {
  instalaciones: string;
  area: any;
  tipo: any;
  mantenedor: any;
  certificacion: string;
  fechaInicio: Date;
};

@Component
export default class ProgramacionEventoTabla extends Vue {
  public headers: Array<Headers> = [
    {
      text: "INSTALACIONES",
      align: "center",
      sortable: true,
      value: "equipo.nombre"
    },
    {
      text: "AREA",
      align: "center",
      sortable: true,
      value: "area.nombre"
    },
    {
      text: "TIPO",
      align: "center",
      sortable: true,
      value: "tipo"
    },
    {
      text: "FRECUENCIA",
      align: "center",
      sortable: true,
      value: "frecuencia"
    },
    {
      text: "REALIZADA POR",
      align: "center",
      sortable: true,
      value: "mantenedor.nombre"
    },
    {
      text: "CERTIFICACION",
      align: "center",
      sortable: true,
      value: "certificacion"
    },
    {
      text: "FECHA DE INICIO",
      align: "center",
      sortable: true,
      value: "fecha_inicio"
    }
  ];
  @State(({ appState }) => appState.condominio) condominio!: any;
  @State(({ appState }) => appState.eventos) eventos!: Array<any>;
  @State(({ appState }) => appState.programaciones) programaciones!: Array<any>;

  @Action obtenerEventosProgramados!: Function;

  get eventosSinEliminados(): Array<Desserts> {
    return this.eventos.filter(evento => evento.estado !== "Eliminado");
  }
  public mostrarEvento(value: any): void {
    this.obtenerEventosProgramados(value.eventos);
    this.$router.push(`/programacion-eventos/${value.id}`);
  }
  public obtenerEstadoColor(item: string): string {
    if (item === "Por hacer") return "orange lighten-1";
    else if (item === "Completado") return "green lighten-1";
    else return "green lighten-1";
  }
  public obtenerPrioridadColor(item: string): string {
    if (item === "Baja") return "prioridad-baja";
    else if (item === "Normal") return "prioridad-normal";
    else return "prioridad-alta";
  }
}
