








































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue } from "vue-property-decorator";
import { Validate } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { State, Action } from "vuex-class";

@Component
export default class ProgramacionForm extends Vue {
  @State(({ appState }) => appState.condominio) condominio!: any;
  @State(({ appState }) => appState.estado) estado!: Array<string>;
  @State(({ appState }) => appState.eventos) eventos!: Array<any>;
  @State(({ appState }) => appState.equipos) equipos!: Array<any>;
  @State(({ appState }) => appState.mantenedores) mantenedores!: Array<any>;
  @State(({ appState }) => appState.tipos) tipos!: Array<string>;
  @State(({ appState }) => appState.frecuencias) frecuencias!: Array<string>;
  @State(({ appState }) => appState.certificaciones) certificaciones!: Array<
    string
  >;
  @State(({ appState }) => appState.areas) areas!: Array<any>;
  @Action definirEventos!: Function;
  @Action registrarEvento!: Function;

  @Validate({ required }) fecha: any = null;
  @Validate({ required }) equipo: any = null;
  @Validate({ required }) mantenedor: any = null;
  @Validate({ required }) frecuencia: any = null;
  @Validate({ required }) tipo: any = null;
  @Validate({ required }) certificacion: any = null;
  @Validate({ required }) area: any = null;
  public isDatePicker = false;
  public file!: string | Blob;
  protected rules = [
    (file: File) =>
      !file || file.size < 2000000 || "El archivo no puede tener mas de 2 mb"
  ];

  protected setFile(file: File): void {
    this.file = file;
  }

  //REGISTRAR NUEVO MANTENEDOR
  protected register(): void {
    const evento = {
      fecha: this.fecha,
      equipo_id: this.equipo.id,
      area_id: this.area.id,
      mantenedor_id: this.mantenedor.id,
      estado: "Por hacer",
      prioridad: "Normal",
      frecuencia: this.frecuencia,
      tipo: this.tipo,
      certificacion: this.certificacion
    };
    const formData = new FormData();
    const data = JSON.stringify(evento);
    formData.append("file", this.file);
    formData.append("data", data);
    console.info(formData);
    this.registrarEvento({ condoId: this.condominio.id, data: formData });
    this.clearForm();
    this.$emit("cerrarForm", false);
  }
  //LIMPIAR FORM
  protected clearForm(): void {
    this.$v.$reset();
    this.fecha = null;
    this.equipo = null;
    this.mantenedor = null;
    this.frecuencia = null;
    this.tipo = null;
    this.certificacion = null;
  }
  //HABILITAR SUBMIT
  get isDisabled(): boolean {
    return !this.mantenedor ||
      !this.frecuencia ||
      !this.area ||
      !this.equipo ||
      !this.tipo ||
      !this.fecha
      ? true
      : false;
  }

  //VALIDAR QUE LA FECHA SELECCIONADA SEA SUPERIOR A HOY
  get validarFecha(): boolean {
    const hoy = new Date();
    const reprogramacion = new Date(`${this.fecha}T00:00:00`);
    return reprogramacion >= hoy ? true : false;
  }
  //VALIDACIONES
  get fechaErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.fecha.$dirty) return errors;
    !this.$v.fecha.required && errors.push("El campo es requerido");
    return errors;
  }
  get equipoErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.equipo.$dirty) return errors;
    !this.$v.equipo.required && errors.push("El campo es requerido");
    return errors;
  }
  get areaErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.area.$dirty) return errors;
    !this.$v.area.required && errors.push("El campo es requerido");
    return errors;
  }
  get mantenedorErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.mantenedor.$dirty) return errors;
    !this.$v.mantenedor.required && errors.push("El campo es requerido");
    return errors;
  }
  get frecuenciaErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.frecuencia.$dirty) return errors;
    !this.$v.frecuencia.required && errors.push("El campo es requerido");
    return errors;
  }
  get tipoErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.tipo.$dirty) return errors;
    !this.$v.tipo.required && errors.push("El campo es requerido");
    return errors;
  }
  get certificacionErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.certificacion.$dirty) return errors;
    !this.$v.certificacion.required && errors.push("El campo es requerido");
    return errors;
  }
}
