














































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from "vue-property-decorator";
import { Validate } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import ProgramacionEventoTabla from "@/components/ProgramacionEventos/ProgramacionEventoTabla.vue";
import ProgramacionForm from "@/components/ProgramacionEventos/ProgramacionForm.vue";
import MantenimientoSinCondominio from "@/components/Mantenimiento/MantenimientoSinCondominio.vue";

import { State, Action } from "vuex-class";

@Component({
  components: {
    ProgramacionEventoTabla,
    ProgramacionForm,
    MantenimientoSinCondominio
  }
})
export default class ProgramacionEventos extends Vue {
  public isDatePickerFrom = false;
  public isDatePickerTo = false;
  @State(({ appState }) => appState.condominios) condominios!: Array<any>;
  @State(({ appState }) => appState.programaciones) programaciones!: Array<any>;
  @State(({ appState }) => appState.tipos) tipos!: Array<string>;
  @State(({ appState }) => appState.ultimaQuery) ultimaQuery!: any;

  @Action obtenerProgramaciones!: Function;
  @Action establecerUltimaQuery!: Function;

  @Validate({ required }) fechaDesde: any = null;
  @Validate({ required }) fechaHasta: any = null;
  @Validate({ required }) tipo: any = null;

  get tieneCondominios(): boolean {
    return this.condominios.length > 0 ? true : false;
  }

  //MODELO PARA MOSTRAR O OCULTAR EL FORMULARIO LATERAL
  public drawer = false;

  mounted() {
    if (this.ultimaQuery.tipo) {
      this.obtenerProgramaciones({
        desde: this.ultimaQuery.desde,
        hasta: this.ultimaQuery.hasta,
        tipo: this.ultimaQuery.tipo
      });
    }
  }

  protected filtrar(): void {
    this.obtenerProgramaciones({
      desde: this.fechaDesde,
      hasta: this.fechaHasta,
      tipo: this.tipo
    });
    this.establecerUltimaQuery({
      desde: this.fechaDesde,
      hasta: this.fechaHasta,
      tipo: this.tipo
    });
  }

  // //VALIDAR QUE LA FECHA SELECCIONADA SEA SUPERIOR A HOY
  // get validarFecha(): boolean {
  //   const hoy = new Date();
  //   const reprogramacion = new Date(
  //     `${this.fechaDesde ?? this.fechaHasta}T00:00:00`
  //   );
  //   return reprogramacion >= hoy ? true : false;
  // }

  //EVENTO EMITIDO DESDE ProgramacionForm PARA CERRAR EL FORMULARIO LATERAL
  public cerrarDrawer(value: boolean): void {
    this.drawer = value;
  }

  get isDisabled(): boolean {
    return !this.fechaHasta || !this.fechaDesde || !this.tipo ? true : false;
  }

  get fechaDesdeErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.fechaDesde.$dirty) return errors;
    !this.$v.fechaDesde.required && errors.push("El campo es requerido");
    return errors;
  }
  get fechaHastaErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.fechaDesde.$dirty) return errors;
    !this.$v.fechaDesde.required && errors.push("El campo es requerido");
    return errors;
  }
  get tipoErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.tipo.$dirty) return errors;
    !this.$v.tipo.required && errors.push("El campo es requerido");
    return errors;
  }
}
